.module-header-coordonnees {
    position: relative;
    .module-header--caption {
        top: 28%;
        left: -30%;
    }
    h3 {
        color: #124248;
    }
    .section-coordonnees.desktop {
        background: #fff;
        padding: 50px;
        position: absolute;
        width: 950px;
        right: 0;
        bottom: 0;
        .left-side {
            width: 50%;
            margin-right: 50px;
            h4 {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-22;
                color: $color-1;
                span {
                    font-weight: bold;
                }
            }
            p {
                font-family: $font-family-3;
                font-weight: normal;
                font-size: $font-size-16;
                line-height: 1.5;
                margin: 0;
                color: #231F20;
            }
        }
        .right-side {
            width: 50%;
            h3 {
                font-family: $font-family-1;
                font-weight: 500;
                font-size: $font-size-24;
                color: $color-1;
                text-transform: initial;
            }
            h4 {
                font-family: $font-family-3;
                font-weight: normal;
                font-size: $font-size-22;
                color: $color-1;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .module-header--caption {
            left: -27%;
        }
        .section-coordonnees.desktop {
            display: none;
        }
        @media screen and (max-width: $size-xs-max) {
            .module-header--caption {
                top: 25%;
                left: -15%;
            }
            @media screen and (max-width: 375px) {
                .module-header--caption {
                    left: -12%;
                }
            }
        }
    }
}

.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 51%;
        width: 100%;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            text-align: center;
            font-family: $font-family-2;
            font-size: $font-size-70;
            font-weight: 500;
            color: #EDEDED;
            text-transform: uppercase;
            padding-bottom: 0;
            //text-shadow: 0 0 10px $color-black;
        }
    }
}

@media screen and (max-width: 1024px) {
    .module-header {
        &--caption {
            top: 65%;
            &--title {
                font-size: 3rem;
            }
            &--subtitle {
                font-size: 1.2rem;
            }
        }
    }
    @media screen and (max-width: $size-sm-max) {
        .module-header {
            &--caption {
                top: 60%;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            .module-header {
                &--caption {
                    padding: 0 5%;
                    &--title {
                        
                    }
                    &--subtitle {
                        line-height: 1.5;
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}