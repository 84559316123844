footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0 5%;
        @extend .items-center;
        @extend .text-color-grey;
        @extend .font-footer;

        .logo {
            padding-bottom: 40px;
        }
        .footer-text {
            .left-side {
                padding-right: 10%;
                .copyright {
                    font-family: $font-family-1;
                    font-weight: normal;
                    font-size: $font-size-16;
                    color: #EDEDED;
                }
            }
            .center-side {
                width: 55%;
                a.link {
                    font-family: $font-family-1;
                    font-weight: normal;
                    font-size: $font-size-14;
                    color: #EDEDED;
                    text-transform: uppercase;
                    transition: 0.3s;
                    &:hover {
                        color: $color-2;
                        transition: 0.3s;
                    }
                }
            }
            .right-side {
                a {
                    position: relative;
                    img {
                        transition: 0.3s;
                        &:nth-child(1) {
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            position: absolute;
                            opacity: 0;
                            left: 0;
                        }
                    }
                    /*&:hover {
                        img {
                            transition: 0.3s;
                            &:nth-child(1) {
                                opacity: 0;
                            }
                            &:nth-child(2) {
                                opacity: 1;
                            }
                        }
                    }*/
                }
            }
        }
        @media screen and (max-width: 1795px){
            .footer-text {
                .left-side {
                    padding-right: 10%;
                }
            }
            @media screen and (max-width: 1695px){
                .footer-text {
                    .left-side {
                        padding-right: 7%;
                    }
                }
                @media screen and (max-width: 1455px){
                    .logo {
                        padding-bottom: 25px;
                        a .img-responsive {
                            margin: auto;
                        }
                    }
                    .footer-text {
                        flex-direction: column-reverse;
                        .center-side {
                            width: 100%;
                            text-align: center;
                            padding: 30px 0;
                        }
                        .left-side {
                            padding-right: 0;
                        }
                    }
                    @media screen and (max-width: $size-xs-max){
                        .logo {
                            a .img-responsive {
                                margin: initial;
                            }
                        }
                        .footer-text {
                            align-items: baseline;
                            .center-side {
                                display: flex;
                                flex-direction: column;
                                align-items: baseline;
                                a.link.mr12 {
                                    margin: 0 0 25px 0;
                                    font-size: 16px;
                                }
                                a.link.mr12.last {
                                    margin: 0 0 40px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1455px){
        min-height: 300px;
        @media screen and (max-width: $size-xs-max){
            min-height: 450px;
        }
    }
}
