// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

p {
    font-family: $font-family-2;
    @media screen and (max-width: $size-xs-max){
        font-size: 16px !important;

    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            top: 40%;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            z-index: $z-index-slide-caption;
            display: flex !important;
            padding: 0 5.5%;
            .left-side {
                h2 {
                    font-family: $font-family-1;
                    font-weight: bold;
                    font-size: $font-size-70;
                    color: $color-white;
                    padding-bottom: 15px;
                }
                p {
                    font-family: $font-family-1;
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: $color-white;
                    margin: 0;
                    line-height: 1.5;
                }
                a.btn-slideshow {
                    background: $color-2;
                    width: 260px;
                    height: 80px;
                    margin-top: 25px;
                    border-radius: 10px;
                    border: 1px solid $color-white;
                    transition: 0.3s;
                    h5 {
                        font-weight: normal;
                        font-size: $font-size-16;
                        text-transform: uppercase;
                        color: $color-white;
                        letter-spacing: 2px;
                        transition: 0.3s;
                    }
                    &:hover {
                        background: $color-white;
                        border: 1px solid $color-1;
                        transition: 0.3s;
                        h5 {
                            color: $color-1;
                            transition: 0.3s;
                        }
                    }
                }
            }
            @media screen and (max-width: 1450px){
                top: 38%;
                .left-side h2 {
                    font-size: 3.2rem;
                }
                @media screen and (max-width: 1180px){
                    top: 35%;
                    .left-side h2 {
                        font-size: 3rem;
                    }
                    @media screen and (max-width: 1100px){
                        top: 34%;
                        .left-side h2 {
                            font-size: 2.7rem;
                        }
                        @media screen and (max-width: $size-sm-max){
                            top: 40%;
                            .left-side {
                                h2 {
                                    font-size: 2.7rem;
                                }
                                p br {
                                    display: none;
                                }
                                a.btn-slideshow {
                                    display: none;
                                }
                            }
                            .fb-arrow {
                                display: none;
                            }
                            @media screen and (max-width: $size-xs-max){
                                top: 35%;
                                .left-side {
                                    h2 {
                                        br {
                                            display: none;
                                        }
                                    }
                                    p {
                                        font-size: 16px;
                                    }
                                    a.btn-slideshow {
                                        display: flex;
                                    }
                                }
                                @media screen and (max-width: 375px){
                                    top: 27%;
                                    @media screen and (max-width: 360px){
                                        top: 30%;
                                        .left-side {
                                            h2 {
                                                font-size: 32px;
                                            }
                                            a.btn-slideshow {
                                                width: 220px;
                                                height: 70px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 35px 0 0 0;
    .left-side {
        width: 45%;
        background: $color-1;
        padding: 100px 0 0 5%;
        position: absolute;
        h3 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-60;
            color: $color-white;
            text-transform: initial;
            padding-bottom: 20px;
            padding-right: 5%;
        }
        p {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-18;
            color: #EDEDED;
            margin: 0;
            line-height: 1.5;
            padding-right: 5%;
        }
        .section-arrow {
            background: $color-2;
            width: 244px;
            height: 250px;
            margin-left: auto;
            margin-top: 75px;
        }
        
    }
    .right-side {
        margin-left: auto;
    }
    @media screen and (max-width: 1875px){
        .right-side {
            width: 75%;
        }
        @media screen and (max-width: 1680px){
            .left-side {
                h3 {
                    font-size: 2.6rem;
                }
            }
            @media screen and (max-width: 1460px){
                .left-side {
                    h3 {
                        font-size: 2.4rem;
                    }
                }
                @media screen and (max-width: 1400px){
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        position: initial;
                        h3 {
                            font-size: 3rem;
                            padding: 0 0 20px 0;
                            br {
                                display: none;
                            }
                        }
                        p {
                            padding-right: 0;
                            width: 80%;
                            br {
                                display: none;
                            }
                        }
                    }
                    .right-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-sm-max){
                        padding: 0;
                        margin-top: -2px;
                        .left-side {
                            padding: 75px 0 0 5%;
                        }
                        @media screen and (max-width: $size-xs-max){
                            .left-side {
                                padding: 75px 5%;
                                .section-arrow {
                                    display: none;
                                }
                                h3 {
                                    font-size: 2.5rem;
                                }
                                p {
                                    width: 100%;
                                }
                            }
                            @media screen and (max-width: 375px){
                                .left-side {
                                    h3 {
                                        font-size: 2.2rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-images {
    .title {
        padding: 100px 5%;
        h4 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-60;
            color: $color-1;
            text-transform: initial;
        }
    }
    .section-images.accordion {
        font-size: 0;
        height: 100%;
        .image.accordion__item {
            font-size: 1rem;
            display: inline-block;
            vertical-align: top;
            height: 100%;
            min-height: 720px;
            box-sizing: border-box;
            position: relative;
            outline: none;
            background-size: cover;
            // border: 1px solid black;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 720px;
            &:nth-child(1) {
                background-image: url("../images/accueil_slide_01.jpg");
            }
            &:nth-child(2) {
                background-image: url("../images/accueil_slide_02.jpg");
            }
            &:nth-child(3) {
                background-image: url("../images/accueil_slide_03.jpg");
            }
            &:nth-child(4) {
                background-image: url("../images/accueil_slide_04.jpg");
            }

            div.txt-box {
                position: absolute;
                top: 75%;
                left: 50%;
                transform: translate(-50% , -50%);
                div.wrapper {
                    position: relative;
                }
                h3 {
                    color: $color-white;
                    font-size: 80px;
                    transition: .5s;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    &.overlay {
                        opacity: 0;
                        visibility: hidden;
                        transition: .5s;
                    }
                    @media screen and (max-width: 1570px){
                        font-size: 65px;
                        @media screen and (max-width: 1250px){
                            display: none;
                        }
                    }
                }
            }
            &.active {
                div.txt-box {
                    h3 {
                        opacity: 0;
                        visibility: hidden;
                        transition: .5s;
                        &.overlay {
                            transition: .5s;
                            opacity: 1;
                            transform: translate(-50% , 0);
                            visibility: visible;;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1775px){
        .title {
            h4 {
                br {
                    display: none;
                }
            }
        }
        @media screen and (max-width: $size-xs-max){
            .title {
                padding: 75px 5%;
                h4 {
                    font-size: 2.3rem;
                }
            }
        }
    }
}

#section-services {
    padding: 100px 5%;
    background: #054F880D;
    margin-top: 50px;
    h3 {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-60;
        color: $color-1;
        text-transform: uppercase;
    }
    .section-bloc {
        padding-top: 50px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        //align-items: start;
        //justify-items: center;
        @media screen and (max-width: 1400px){
            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: $size-sm-max){
                grid-template-columns: repeat(2, 1fr);
                @media screen and (max-width: $size-xs-max){
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
        .bloc {
            padding: 35px 15px;
            height: 24vw;
            min-height: 310px;
            background: $color-1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .title {
                margin-bottom: 50px;
                h2 {
                    font-family: $font-family-2;
                    font-size: $font-size-120;
                    font-weight: normal;
                    color: $color-white;
                } 
            }
            .text {
                h4 {
                    font-family: $font-family-2;
                    font-size: $font-size-24;
                    font-weight: normal;
                    text-align: center;
                    color: $color-white;
                }
            }
        }
    }
    @media screen and (max-width: 1680px){
        .section-bloc .bloc .text h4 {
            font-size: 1.1rem;
        }
        @media screen and (max-width: 1555px){
            .section-bloc .bloc .text h4 {
                font-size: 1rem;
            }
            @media screen and (max-width: 1400px){
                .section-bloc .bloc .text h4 {
                    font-size: 1.2rem;
                }
                @media screen and (max-width: $size-xs-max){
                    margin-top: -200px;
                    h3 {
                        br {
                            display: none;
                        }
                    }
                    .section-bloc .bloc .title h2 {
                        font-size: 7rem;
                    }
                }
            }
        }
    }
}

#section-text-logo {
    padding: 75px 5%;
    .left-side {
        h4 {
            font-weight: 600;
            font-size: $font-size-40;
            color: $color-1;
            line-height: 1.3;
        }
    }
    @media screen and (max-width: 1100px){
        flex-direction: column;
        .left-side {
            text-align: center;
            margin-bottom: 35px;
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-bloc-image {
    padding: 50px 0 0 5%;
    position: relative;
    .left-side {
        width: 35%;
        margin-right: 15px;
        .contenu {
            background: #CE1D2A;
            padding: 150px 35px;
            h4 {
                font-weight: bold;
                font-size: $font-size-30;
                text-transform: initial;
                padding-bottom: 25px;
                color: #EDEDED;
                line-height: 1.3;
            }
            p {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-18;
                line-height: 1.5;
                margin: 0;
                color: #EDEDED;
            }
        }
    }
    .right-side {
        width: 65%;
    }
    &::before {
        content: '';
        background: $color-1;
        width: 38%;
        height: 82%;
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
    }
    @media screen and (max-width: 1875px){
        &::before {
            width: 37.95%;
        }
        @media screen and (max-width: 1630px){
            .left-side .contenu {
                padding: 125px 35px;
            }
            &::before {
                width: 37.89%;
            }
            @media screen and (max-width: 1465px){
                .left-side .contenu {
                    padding: 100px 35px;
                }
                @media screen and (max-width: 1225px){
                    padding: 0;
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        margin: 0;
                    }
                    .right-side {
                        width: 100%;
                    }
                    &::before {
                        display: none;
                    }
                    @media screen and (max-width: $size-xs-max){
                        .left-side .contenu {
                            padding: 75px 5%;
                        }
                    }
                }
            }
        }
    }
}

#section-image-bulle {
    padding: 50px 5% 50px 0;
    position: relative;
    .left-side {
        width: 65%;
    }
    .right-side {
        width: 40%;
        background: #EDEDED;
        padding: 75px 50px;
        position: absolute;
        right: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            text-transform: initial;
            padding-bottom: 25px;
            color: $color-1;
            line-height: 1.3;
        }
        p {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-18;
            line-height: 1.5;
            margin: 0;
            color: #231F20;
        }
        a.btn-border {
            width: 260px;
            height: 80px;
            margin-top: 50px;
            border-radius: 10px;
            border: 1px solid $color-1;
            transition: 0.3s;
            h5 {
                font-weight: normal;
                font-size: $font-size-16;
                text-transform: uppercase;
                color: $color-1;
                letter-spacing: 2px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-white;
                border: 1px solid $color-1;
                transition: 0.3s;
            }
        }
        a.btn-red {
            background: $color-2;
            width: 260px;
            height: 80px;
            margin-top: 50px;
            border-radius: 10px;
            border: 1px solid $color-white;
            transition: 0.3s;
            h5 {
                font-weight: normal;
                font-size: $font-size-16;
                text-transform: uppercase;
                color: $color-white;
                letter-spacing: 2px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-white;
                border: 1px solid $color-1;
                transition: 0.3s;
                h5 {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }
    }
    @media screen and (max-width: 1530px){
        .right-side {
            padding: 55px 50px;
        }
        @media screen and (max-width: 1430px){
            .right-side {
                width: 45%;
            }
            @media screen and (max-width: 1335px){
                padding: 50px 0;
                .left-side {
                    width: 60%;
                }
                .right-side {
                    width: 40%;
                    padding: 47px 50px;
                    position: initial;
                }
                @media screen and (max-width: 1270px){
                    flex-direction: column;
                    .left-side {
                        width: 75%;
                    }
                    .right-side {
                        width: 100%;
                        padding: 75px 5%;
                    }
                    @media screen and (max-width: $size-sm-max){
                        .left-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#section-image-text {
    background: #CE1D2A1A;
    padding: 50px 5%;
    .left-side {
        width: 50%;
        margin-right: 50px;
    }
    .right-side {
        width: 40%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            text-transform: initial;
            padding-bottom: 25px;
            color: $color-1;
            line-height: 1.3;
        }
        p {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-18;
            line-height: 1.5;
            margin: 0;
            color: #231F20;
        }
        a.btn-red {
            background: $color-2;
            width: 260px;
            height: 80px;
            margin-top: 50px;
            border-radius: 10px;
            border: 1px solid $color-white;
            transition: 0.3s;
            h5 {
                font-weight: normal;
                font-size: $font-size-16;
                text-transform: uppercase;
                color: $color-white;
                letter-spacing: 2px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-white;
                border: 1px solid $color-1;
                transition: 0.3s;
                h5 {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }
    }

    &.blue {
        background: #054F881A;
    }
    @media screen and (max-width: 1100px) {
        flex-direction: column;
        .left-side {
            width: 75%;
            margin: 0 0 50px 0;
        }
        .right-side {
            width: 100%;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side {
                width: 100%;
            }
        }
    }
}

#section-texte-image {
    padding: 50px 0 50px 5%;
    .left-side {
        background: #CE1D2A;
        width: 40%;
        height: 637px;
        padding: 0 3%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            padding-bottom: 25px;
            color: #EDEDED;
            line-height: 1.3;
        }
        p {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-18;
            line-height: 1.5;
            margin: 0;
            color: #EDEDED;
        }
    }
    .right-side {
        width: 60%;
        background: url(../images/services_section04_img01.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        height: 637px
    }
    @media screen and (max-width: 1100px) {
        padding: 50px 5%;
        flex-direction: column;
        .left-side {
            width: 100%;
            height: initial;
            padding: 75px 5%;
        }
        .right-side {
            width: 100%;
            height: initial;
        }
        @media screen and (max-width: $size-xs-max) {
            .left-side h3 br {
                display: none;
            }
            .right-side {
                background: url(../images/services_section04_img01_m.jpg) no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

/*******************************************************************************
* PARTENAIRES
*******************************************************************************/

#section-bloc-image.partenaires {
    padding: 50px 0 0 5%;
    position: relative;
    .left-side {
        width: 35%;
        margin-right: 15px;
        .contenu {
            background: $color-1;
            padding: 80px 35px;
            h4 {
                font-weight: bold;
                font-size: $font-size-30;
                text-transform: initial;
                padding-bottom: 25px;
                color: #EDEDED;
                line-height: 1.3;
            }
            p {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-18;
                line-height: 1.5;
                margin: 0;
                color: #EDEDED;
            }
            a.btn-red {
                background: $color-2;
                width: 260px;
                height: 80px;
                margin-top: 35px;
                border-radius: 10px;
                border: 1px solid $color-white;
                transition: 0.3s;
                h5 {
                    font-weight: normal;
                    font-size: $font-size-16;
                    text-transform: uppercase;
                    color: $color-white;
                    letter-spacing: 2px;
                    transition: 0.3s;
                }
                &:hover {
                    background: $color-white;
                    border: 1px solid $color-1;
                    transition: 0.3s;
                    h5 {
                        color: $color-1;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
    .right-side {
        width: 65%;
    }
    &::before {
        content: '';
        background: $color-2;
        width: 38%;
        height: 82%;
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
    }
    @media screen and (max-width: 1875px){
        &::before {
            width: 37.95%;
        }
        @media screen and (max-width: 1635px){
            .left-side .contenu {
                padding: 45px 35px;
            }
            &::before {
                width: 37.89%;
            }
            @media screen and (max-width: 1465px){
                align-items: center;
                @media screen and (max-width: 1385px){
                    &::before {
                        display: none;
                    }
                    @media screen and (max-width: 1225px){
                        padding: 0;
                        flex-direction: column;
                        .left-side {
                            width: 100%;
                            margin: 0;
                        }
                        .right-side {
                            width: 100%;
                        }
                        @media screen and (max-width: $size-xs-max){
                            .left-side .contenu {
                                padding: 75px 5%;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-image-bulle.partenaires {
    padding: 50px 5% 50px 0;
    position: relative;
    .left-side {
        width: 65%;
    }
    .right-side {
        width: 40%;
        background: #EDEDED;
        padding: 75px 50px;
        position: absolute;
        right: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            text-transform: initial;
            padding-bottom: 25px;
            color: $color-1;
            line-height: 1.3;
        }
        p {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-18;
            line-height: 1.5;
            margin: 0;
            color: #231F20;
        }
        a.btn-border {
            width: 260px;
            height: 80px;
            margin-top: 50px;
            border-radius: 10px;
            border: 1px solid $color-1;
            transition: 0.3s;
            h5 {
                font-weight: normal;
                font-size: $font-size-16;
                text-transform: uppercase;
                color: $color-1;
                letter-spacing: 2px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-white;
                border: 1px solid $color-1;
                transition: 0.3s;
            }
        }
        a.btn-red {
            background: $color-2;
            width: 260px;
            height: 80px;
            margin-top: 50px;
            border-radius: 10px;
            border: 1px solid $color-white;
            transition: 0.3s;
            h5 {
                font-weight: normal;
                font-size: $font-size-16;
                text-transform: uppercase;
                color: $color-white;
                letter-spacing: 2px;
                transition: 0.3s;
            }
            &:hover {
                background: $color-white;
                border: 1px solid $color-1;
                transition: 0.3s;
                h5 {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }
    }
    @media screen and (max-width: 1530px){
        .right-side {
            padding: 55px 50px;
        }
        @media screen and (max-width: 1430px){
            .right-side {
                width: 45%;
            }
            @media screen and (max-width: 1335px){
                padding: 50px 0;
                .left-side {
                    width: 60%;
                }
                .right-side {
                    width: 40%;
                    padding: 65px 50px;
                    position: initial;
                }
                @media screen and (max-width: 1270px){
                    flex-direction: column;
                    .left-side {
                        width: 75%;
                    }
                    .right-side {
                        width: 100%;
                        padding: 75px 5%;
                    }
                    @media screen and (max-width: $size-sm-max){
                        .left-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#section-logo {
    padding: 0 5% 25px 5%;
    .logo {
        width: 15%;
        padding: 50px 5px;
        margin: auto;
        .img-responsive {
            margin: auto;
        }
    }
    @media screen and (max-width: $size-sm-max){
        padding: 0 5% 50px 5%;
        .logo {
            width: 33%;
        }
        @media screen and (max-width: $size-xs-max){
            .logo {
                width: 43%;
                padding: 35px 0;
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 75px 5%;
    h3 {
        font-family: $font-family-1;
        font-weight: bold;
        font-size: $font-size-48;
        color: $color-1;
        padding-bottom: 35px;
    }
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
    }
    #blocIntro_promotions {
        h3 {
            color: $color-1;
            line-height: 1.3;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees.mobile {
    background: #fff;
    padding: 75px 5%;
    display: none;
    .left-side {
        width: 50%;
        margin-right: 50px;
        h4 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-24;
            color: $color-1;
            span {
                font-weight: bold;
            }
        }
        p {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-18;
            line-height: 1.5;
            margin: 0;
            color: #231F20;
        }
    }
    .right-side {
        width: 50%;
        h3 {
            font-family: $font-family-1;
            font-weight: 500;
            font-size: $font-size-26;
            color: $color-1;
            text-transform: initial;
        }
        h4 {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-24;
            color: $color-1;
        }
    }
    @media screen and (max-width: $size-md-max) {
        display: flex;
        padding: 75px 5% 0 5%;
        @media screen and (max-width: $size-xs-max) {
            flex-direction: column;
            padding: 60px 5% 0 5%;
            .left-side {
                width: 100%;
                margin: 0 0 35px 0;
            }
            .right-side {
                width: 100%;
            }
        }
    }
}

#section-heures-map {
    padding: 75px 5%;
    .left-side {
        width: 50%;
        margin-right: 5%;
        
    }
    .right-side {
        width: 50%;
        h4 {
            font-family: $font-family-1;
            font-weight: bold;
            font-size: $font-size-20;
            line-height: 4;
            color: $color-1;
        }
        h5 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-20;
            line-height: 4;
            color: $color-1;
        }
        .jour {
            width: 50%;
        }
    }
    @media screen and (max-width: 1250px) {
        flex-direction: column-reverse;
        .right-side {
            width: 100%;
            margin-bottom: 50px;
            h4, h5 {
                line-height: 3;
            }
            .jour {
                width: 25%;
            }
        }
        .left-side {
            width: 100%;
            margin-right: 0;
        }
        @media screen and (max-width: $size-xs-max) {
            .right-side {
                .jour {
                    width: 50%;
                }
            }
            .left-side {
                .form-group.col-xs-12.col-sm-6.pl0 {
                    padding: 0;
                }
                .btn {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

#section-map {
    padding: 0 5% 75px 5%;
}

#content {
    h3#firstHeading, p a {
        color: $color-1;
    }
}






/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {


}

@media screen and (max-width: $size-sm-max) {


}

@media screen and (max-width: $size-xs-max) {


}
