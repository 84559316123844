.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 40%;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        display: flex !important;
        padding: 0 5%;
        .left-side {
            h2 {
                font-family: $font-family-1;
                font-weight: bold;
                font-size: $font-size-70;
                color: $color-white;
                padding-bottom: 15px;
            }
            p {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-18;
                color: $color-white;
                margin: 0;
                line-height: 1.5;
            }
            a.btn-slideshow {
                background: $color-2;
                width: 260px;
                height: 80px;
                margin-top: 25px;
                border-radius: 10px;
                border: 1px solid $color-white;
                transition: 0.3s;
                h5 {
                    font-weight: normal;
                    font-size: $font-size-16;
                    text-transform: uppercase;
                    color: $color-white;
                    letter-spacing: 2px;
                    transition: 0.3s;
                }
                &:hover {
                    background: $color-white;
                    border: 1px solid $color-1;
                    transition: 0.3s;
                    h5 {
                        color: $color-1;
                        transition: 0.3s;
                    }
                }
            }
        }
    }

    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        	width: 100vw;
        	height: auto;
        	display: block;
            @media screen and (max-width: $size-slider-breakpoint){
              //  height: calc(100vw / 480 * 880);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                    left: 0;
          			z-index: 10;
                    opacity: 1;
                    transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
                &.mobile {
                    display: block;
                }
                &.ecran {
                    display: none;
                }
        	}
    }
}
